import {useAuth} from "../hooks/useAuth";
import {Navigate, Outlet} from "react-router-dom";

export const ProtectedRoute = () => {
    //@ts-ignore
    const { isAuthenticated } = useAuth();

    // Check if the user is authenticated
    if (!isAuthenticated) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }

    // If authenticated, render the child routes
    return <Outlet />;
};