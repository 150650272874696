import {UserResponse} from "./UserInterface";

export class User {

    id: number;
    isDistrictAdmin: boolean;
    schoolIconUrl: string | null;
    email: string;
    profileType: number;
    langId: string | null;
    isSchoolMember: boolean;
    isSchoolStudent: boolean;
    isPublisherAdmin: boolean;
    birthDate: Date;
    gender: number;
    schoolId: number;
    isSchoolAdmin: boolean;
    firstName: string;
    photoUrl: string;
    profileTypeLabel: string;
    username: string;
    isSchoolTeacher: boolean;
    lastName: string;
    isStaff: boolean;
    isSuperuser: boolean;
    regulationInformation: boolean;
    schoolIsActive: boolean;
    chatToken: string;
    schoolToolsStatus: any;

    constructor(response: UserResponse) {
        this.id = response.id;
        this.isDistrictAdmin = response.is_district_admin;
        this.schoolIconUrl = response.school_icon_url;
        this.email = response.email;
        this.profileType = response.profile_type;
        this.langId = response.lang_id;
        this.isSchoolMember = response.is_school_member;
        this.isSchoolStudent = response.is_school_student;
        this.isPublisherAdmin = response.is_publisher_admin;
        this.birthDate = new Date(response.birth_date);
        this.gender = response.gender;
        this.schoolId = response.school_id;
        this.isSchoolAdmin = response.is_school_admin;
        this.firstName = response.first_name;
        this.photoUrl = response.photo_url;
        this.profileTypeLabel = response.profile_type_label;
        this.username = response.username;
        this.isSchoolTeacher = response.is_school_teacher;
        this.lastName = response.last_name;
        this.isStaff = response.is_staff;
        this.isSuperuser = response.is_superuser;
        this.regulationInformation = response.regulation_information;
        this.schoolIsActive = response.school_is_active;
        this.chatToken = response.chat_token;
        this.schoolToolsStatus = {
            forum: response.school_tools_status.forum,
            blog: response.school_tools_status.blog,
            news: response.school_tools_status.news,
            calendar: response.school_tools_status.calendar,
        };
    }


}
