import axios, {Axios, AxiosInstance} from "axios";
import env from "../env";
import {UserResponse} from "../Services/UserInterface";


export default class HttpClient {


    private static instance: HttpClient;
    public readonly axiosInstance: Axios;

    constructor() {
        this.axiosInstance = axios.create({
            'baseURL': env.baseURL,
        })

        this.initializeInterceptors();

    }
    public static getInstance(): HttpClient {
        if (!HttpClient.instance) {
            HttpClient.instance = new HttpClient();
        }
        return HttpClient.instance;
    }


    private initializeInterceptors() {
        this.axiosInstance.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `JWT ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

;
    }

    public refreshAccessToken() {
        return axios.post<{ token: string }>(
            env.baseURL + 'api/v2/jwt/refresh-token/',
            {
                token: localStorage.getItem('token'),
            },
        );
    }

    public obtainToken(userCredentials: {
        username: string;
        password: string;

    }) {
        console.log('obtain token')
        return this.axiosInstance.post('api/v2/jwt/obtain-token/', JSON.stringify({
            username: userCredentials.username,
            password: userCredentials.password,
        }), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }


    public getUser() {
        return this.axiosInstance.get<UserResponse>('/api/v2/user/');
    }
}