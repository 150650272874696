import {useAuth} from "../hooks/useAuth";

import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {ProtectedRoute} from "./ProtectedRoute";
import Login from "../Pages/Login";
import Logout from "../Pages/Logout";
import {Home} from "../Pages/Home";
import {Layout} from "../components/navigation/Layout";

const Routes = () => {
    //@ts-ignore
    const {isAuthenticated} = useAuth();
    // const token = localStorage.getItem('token');

    // Define public routes accessible to all users
    const routesForPublic = [
        {
            path: "/home",
            element: <Home />,
        },
        {
            path: "/about-us",
            element: <div>About Us</div>,
        },
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/",
                    element: <div>User Home Page</div>,
                },
                {
                    path: "/profile",
                    element: <div>User Profile</div>,
                },
                {
                    path: "/logout",
                    element: <Logout/>,
                },
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <div>Home Page</div>,
        },
        {
            path: "/login",
            element: <Login/>,
        },

        {
            path: "*",
            element: <Navigate to="/home" replace />,
        },
    ];

    const redirect = [
        {
            path: "*",
            element: <Navigate to="/home" replace />,
        },
    ]

    const routes = [
        {
            path: '/',
            element: <Layout />, // Use Layout as the root element
            children: [
                ...routesForPublic,
                ...(!isAuthenticated ? routesForNotAuthenticatedOnly : redirect),
                ...routesForAuthenticatedOnly,
            ],
        },
    ];


    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter(routes);



    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>;
};

export default Routes;