import React, {useEffect} from 'react';
import './App.css';
import Routes from "./routes";
import Header from "./components/navigation/Header";


function App() {


    return (
        <Routes/>

    );
}

export default App;
