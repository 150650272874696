import axios from "axios";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import HttpClient from "../network/HttpClient";
import {User} from "../Services/User";
import {Navigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";

interface AuthContextProps {
    token: string | null;
    setToken: (newToken: string | null) => void;
    login: (credentials: { username: string, password: string }) => Promise<boolean>;
    user: User | null;
    isAuthenticated: boolean;
}


const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider = ({children}: any) => {
    const [token, setToken_] = useState<string | null>(localStorage.getItem("token"));
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const setToken = (newToken: string | null) => {
        setToken_(newToken);
    };

    const checkTokenValidity = (token: string | null) => {
        if (token) {
            const decodedToken: { exp: number } = jwtDecode(token);
            return (decodedToken.exp * 1000) > Date.now();
        }
        return false;
    };

    const login = async (credentials: { username: string, password: string }) => {
        const httpClient = HttpClient.getInstance();
        try {
            const res = await httpClient.obtainToken(credentials);

            if (res.data.token) {
                setToken(res.data.token);

                const user = await httpClient.axiosInstance.get('/api/v2/user/', {
                    headers: {
                        Authorization: `JWT ${res.data.token}`
                    }
                });
                setUser(new User(user.data))


                return true;
            }
        } catch (e) {
            return false;

        }

        return false;
    }

    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('token')
    }

    useEffect( () => {
        const isValid = checkTokenValidity(token);
        setIsAuthenticated(isValid);

        if (token && isValid) {
            localStorage.setItem('token', token);
            if (user === null) {
                const httpClient = HttpClient.getInstance();
                httpClient.axiosInstance.get('/api/v2/user/', {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                }).then(res => {
                    setUser(new User(res.data))
                })
            }
        } else {
            localStorage.removeItem('token');
            setUser(null);
        }
    }, [token]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            login,
            user,
            isAuthenticated,
        }),
        [token, user, isAuthenticated]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};


export default AuthProvider;